<template>
	<v-dialog v-model="dialog" persistent max-width="900px">
		<v-card>
			<!-- Header -->
			<v-card-title class="d-flex justify-space-between border-bottom">
				<h5 class="subtitle-1 font-weight-regular">Создание вопроса</h5>
				<v-btn color="dark" @click="closeModal" icon>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>

			<!-- Progress bar -->
			<v-progress-linear
				v-if="loading"
				color="primary"
				height="5"
				indeterminate
			></v-progress-linear>

			<!-- Body -->
			<v-card-text>
				<v-container>
					<v-form ref="form">
						<v-row>
							<!-- Тема вопроса -->
							<v-col cols="12">
								<span class="subtitle-2 font-weight-light">
									Тема вопроса
								</span>
								<v-text-field
									v-model="data.name"
									class="border-all"
									solo
									hide-details
									flat
									dense
									:rules="[v => !!v]"
								>
								</v-text-field>
							</v-col>
							<!-- Категория -->
							<v-col cols="12" class="pt-0">
								<span class="subtitle-2 font-weight-light">
									Категория
								</span>
								<v-autocomplete
									v-model="data.category_id"
									class="border-all"
									solo
									hide-details
									flat
									dense
									:items="catalog.request_categories"
									item-text="name"
									item-value="id"
									:rules="[v => !!v]"
								>
								</v-autocomplete>
							</v-col>
							<!-- Alert -->
							<v-col cols="12" class="py-0">
								<v-alert
									v-if="alert"
									class="mb-0"
									:type="alert.color"
									:color="alert.color"
									outlined
									dense
								>
									{{ alert.message }}
								</v-alert>
							</v-col>
						</v-row>
					</v-form>
				</v-container>
			</v-card-text>

			<v-card-actions class="pt-0 pb-5 d-flex justify-end">
				<v-btn
					class="subtitle-2 text-capitalize"
					color="primary"
					depressed
					:loading="loading"
					@click="addQuestion"
				>
					{{ toEdit ? "Сохранить" : "Добавить" }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "FaqModal",

	props: {
		toEdit: {
			type: Object,
			required: false,
			default: undefined,
		},
	},

	data() {
		return {
			alert: null,
			dialog: true,
			loading: false,
			data: {
				category_id: null,
				name: "",
			},
		};
	},

	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			houses: "ksk/GET_HOUSES",
			role: "auth/GET_USER_ROLE_VUEX",
		}),
	},

	watch: {
		alert(val) {
			if (!val) return;
			setTimeout(() => {
				this.alert = null;
			}, 3000);
		},
	},

	methods: {
		// Валидация
		customValidation() {
			return this.$refs.form.validate();
		},
		// Добавление заявки
		async addQuestion() {
			this.alert = null;

			if (!this.customValidation()) return;

			try {
				this.loading = true;

				let res;

				if (!this.toEdit) {
					res = await this.$api[this.role].add_question(this.data);
				} else {
					console.log(this.data);
					res = await this.$api[this.role].edit_question({
						id: this.data._id,
						data: this.data,
					});
				}

				this.alert = {
					color: "success",
					message: res.success,
				};

				if (this.toEdit) {
					setTimeout(() => this.closeModal(), 500);
				}

				this.data = {
					category_id: null,
					name: "",
					answer: "",
				};

				this.$refs.form.reset();

				this.$emit("update-info");
			} catch (error) {
				this.alert = {
					color: "error",
					message: error.message || "Произошла ошибка. Повторите еще раз.",
				};
			} finally {
				this.loading = false;
			}
		},

		closeModal() {
			this.dialog = false;
			this.$emit("close-modal");
		},
	},

	created() {
		if (this.toEdit) {
			this.data = { ...this.toEdit };
		}
	},
};
</script>
